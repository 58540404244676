<template>
  <v-card class="pa-8" width="100%">
    <loading :active.sync="loading"></loading>
    <v-row class="pt-4 pb-10">
      <v-btn @click="cancel()">กลับ</v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="submit()" color="primary">บันทึก</v-btn>
    </v-row>
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <h3 class="pa-4" style="font-weight: 800">รายละเอียดออเดอร์</h3>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>หมายเลขคำสั่งซื้อ</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ tranxDetail.transactionNo }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>วันและเวลาสั่งซื้อ</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ convertDate(tranxDetail.createdAt) }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>ชื่อผู้สั่ง</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span
            >{{ tranxDetail.receiveFromFirstName || user.firstName }}
            {{ tranxDetail.receiveFromLastName || user.lastName }}</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>หมายเลขโทรศัพท์</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ tranxDetail.receiveFromTel || user.telNumber }} </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>ซื้อให้ผู้อื่น</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ tranxDetail.buyToFlag ? "ใช่" : "ไม่ใช่" }} </span>
        </v-col>
      </v-row>
      <v-row v-if="tranxDetail.buyToFlag">
        <v-col cols="12" md="3" sm="3"><span>ซื้อให้ ชื่อ</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span
            >{{ tranxDetail.receiveFirstName }}
            {{ tranxDetail.receiveLastName }}</span
          >
        </v-col>
      </v-row>
      <v-row v-if="tranxDetail.buyToFlag">
        <v-col cols="12" md="3" sm="3"
          ><span>ซื้อให้ หมายเลขโทรศัพท์</span>
        </v-col>
        <v-col cols="12" md="9" sm="9">
          <span>{{ tranxDetail.receiveTel }} </span>
        </v-col>
      </v-row>
      <v-row v-if="tranxDetail.agentId">
        <v-col cols="12" md="3" sm="3"><span>ซื้อผ่านตัวแทน</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <span
            >{{ tranxDetail.agentData.firstName }}
            {{ tranxDetail.agentData.lastName }} ({{
              tranxDetail.agentData.agentNo
            }})</span
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3" sm="3" class="pt-5"><span>สถานะ</span> </v-col>
        <v-col cols="12" md="7" sm="9">
          <v-select
            v-model="status"
            :items="itemsStatus"
            item-text="text"
            item-value="value"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <!-- <v-row dense>
        <v-col cols="12" md="3" sm="3" class="pt-5"
          ><span>สถานะการจัดส่ง</span>
        </v-col>
        <v-col cols="12" md="7" sm="9">
          <v-select
            v-model="deliveryStatus"
            :items="itemsDeliveryStatus"
            item-text="text"
            item-value="value"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="12" md="3" sm="3" class="pt-5"
          ><span>หมายเหตุ</span>
        </v-col>
        <v-col cols="12" md="7" sm="9">
          <v-text-field placeholder="หมายเหตุ" v-model="remark" dense solo>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3" class="pt-5"
          ><span>แก้ไขโดย</span>
        </v-col>
        <v-col cols="12" md="7" sm="9">
          <span>{{ showUserName || "-" }}</span>
          <!-- <v-text-field placeholder="หมายเหตุ" v-model="remark" dense solo>
          </v-text-field> -->
        </v-col>
      </v-row>
      <!-- <v-row align="center" justify="center" class="mt-1">
        <v-col cols="12" md="12" sm="6" xs="12">
          <v-card outlined class="pa-4">
            <v-row no-gutters align="center" justify="center">
              <v-col cols="12" class="pt-6">
                <span>อัพโหลดรูปสลิปที่นี่</span>
                <span style="color: red">*</span>
              </v-col>
              <v-col cols="11" class="mt-5 ma-10" id="step-3">
                <v-row align="center" justify="center">
                  <v-img
                    v-model="picture_1"
                    @click="changePic1()"
                    src="@/assets/upload.png"
                    class="mr-3"
                    max-width="30"
                  >
                  </v-img>
                  <span>เลือกรูปภาพ</span>
                </v-row>
                <v-row align="center" justify="center" class="mt-4">
                  <v-col
                    cols="5"
                    md="5"
                    class="
                      pl-5
                      pr-5
                      pb-5
                      row
                      fill-height
                      sortable-list
                      align-center
                    "
                  >
                    <v-card dense light class="pa-1" v-if="showImage">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-icon small light @click="RemoveImage()"
                          >mdi-close</v-icon
                        >
                      </v-card-actions>

                      <v-img
                        :src="showImage"
                        width="200px"
                        aspect-ratio="0.6"
                        contain
                        @click="newWindow(showImage)"
                      ></v-img>
                    </v-card>
                  </v-col>
                </v-row>
                <input
                  type="file"
                  ref="image"
                  id="picTure1"
                  accept="image/png"
                  @change="showPicture1($event)"
                  style="display: none"
                  hidden
              /></v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row> -->
      <v-row v-if="tranxDetail.slipImgUrl != ''">
        <v-col cols="12" md="3" sm="3"><span>รูป slip</span> </v-col>
        <!-- <v-col cols="12" md="9" sm="9">
          <v-img
            :src="tranxDetail.slipImgUrl"
            max-width="400px"
            :max-height="auto"
          ></v-img>
        </v-col> -->
        <v-col cols="12" md="9" sm="9">
          <v-card outlined>
            <v-row no-gutters align="center">
              <v-col cols="10" md="10" sm="10" xs="10" class="ma-4" id="step-3">
                <span>รูปแสกนสลิปโอนเงิน</span>

                <v-img :src="showImage2" width="200px"></v-img>
                <v-text-field
                  v-model="picture_2"
                  @click="changePic2()"
                  :placeholder="imageName2"
                  prepend-icon="mdi-camera"
                  readonly
                >
                </v-text-field
                ><input
                  type="file"
                  ref="image"
                  id="picTure2"
                  accept="image/*"
                  @change="showPicture2($event)"
                  style="display: none"
              /></v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" sm="3"><span>รูปแสกนลอตเตอรี่ลาว</span> </v-col>
        <v-col cols="12" md="9" sm="9">
          <v-card outlined>
            <v-row no-gutters align="center">
              <v-col cols="10" md="10" sm="10" xs="10" class="ma-4" id="step-3">
                <span>รูปแสกนลอตเตอรี่ลาว</span>

                <v-img :src="showImage" width="200px"></v-img>
                <v-text-field
                  v-model="picture_1"
                  @click="changePic1()"
                  :placeholder="imageName1"
                  prepend-icon="mdi-camera"
                  readonly
                >
                </v-text-field
                ><input
                  type="file"
                  ref="image"
                  id="picTure1"
                  accept="image/*"
                  @change="showPicture1($event)"
                  style="display: none"
              /></v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <h3 class="pt-8" style="font-weight: 800">ออเดอร์ที่สั่ง</h3>
      <v-row>
        <v-col cols="12" md="12">
          <v-data-table
            :headers="headers"
            :items="tranxDetail.numberData"
            :search="search"
            :items-per-page="10"
            disable-sort
            hide-default-footer
            disable-pagination
            no-data-text="ไม่มีข้อมูล"
            class="elevation-1"
          >
            <template v-slot:[`item.count`]="{ index }">
              <!-- <span>{{ item.count.indexOf() + 1 }}</span> -->
              <span>{{ index + 1 }}</span>
            </template>

            <template v-slot:[`item.numberData`]="{ item }">
              <v-chip
                color="#005BA6"
                style="margin-right: 1px; padding: 7px"
                v-for="(powerball, index) of item.set"
                :key="`powerball_${index}`"
                x-small
              >
                <span style="font-size: 1rem; font-weight: 700; color: #fff">{{
                  powerball
                }}</span>
              </v-chip>
            </template>

            <template v-slot:[`item.price`]="{ item }">
              <span style="font-size: 1rem; font-weight: 700; color: #000"
                >ราคา {{ item.price * 100 }} กีบ <br />
                ({{ item.price }} บาท)</span
              >
            </template>
            <template v-slot:[`item.cancel`]="{ item }">
              <!-- <span>{{ item.count.indexOf() + 1 }}</span> -->
              <v-checkbox color="red" v-model="item.isCancel"></v-checkbox>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" md="12">
          <v-card outlined>
            <v-row class="text-center" align="center" justify="center">
              <v-col cols="12" class="py-6">
                  <v-row align="center" justify="center" class="text-center">
                    <v-col cols="12">
                      เลือกสลาก
                    </v-col>
                    <v-col
                      style="
                        border: 1px solid #000;
                        height: 40px;
                        background-color: #0093dd;
                        color: white;
                      "
                      cols="4"
                      class="pa-0 pt-3"
                    >
                      <p style="margin-top: -3px">เลือกประเภทสลาก</p>
                      <!-- <p style="margin-top: -3px">กรอกเลขสลากที่ต้องการซื้อ</p> -->
                    </v-col>

                    <v-col
                      cols="1"
                      style="
                        border: 1px solid #000;
                        height: 40px;
                        background-color: #fcdc00;
                        text-align: center;
                      "
                      @click="onChangeMultipleAmt('2')"
                      v-if="buyType == '2'"
                    >
                      <p
                        style="margin-top: -3px"
                        @click="onChangeMultipleAmt('2')"
                      >
                        2
                      </p>
                    </v-col>
                    <v-col
                      cols="1"
                      style="border: 1px solid #000; height: 40px"
                      @click="onChangeMultipleAmt('2')"
                      v-else
                    >
                      <p
                        style="margin-top: -3px"
                        @click="onChangeMultipleAmt('2')"
                      >
                        2
                      </p>
                    </v-col>
                    <v-col
                      @click="onChangeMultipleAmt('3')"
                      cols="1"
                      style="
                        border: 1px solid #000;
                        height: 40px;
                        background-color: #fcdc00;
                        text-align: center;
                      "
                      v-if="buyType == '3'"
                    >
                      <p style="margin-top: -3px">3</p>
                    </v-col>
                    <v-col
                      @click="onChangeMultipleAmt('3')"
                      cols="1"
                      style="border: 1px solid #000; height: 40px"
                      v-else
                    >
                      <p style="margin-top: -3px">3</p>
                    </v-col>
                    <v-col
                      @click="onChangeMultipleAmt('4')"
                      cols="1"
                      style="
                        border: 1px solid #000;
                        height: 40px;
                        background-color: #fcdc00;
                        text-align: center;
                      "
                      v-if="buyType == '4'"
                    >
                      <p style="margin-top: -3px">4</p>
                    </v-col>
                    <v-col
                      @click="onChangeMultipleAmt('4')"
                      cols="1"
                      style="border: 1px solid #000; height: 40px"
                      v-else
                    >
                      <p style="margin-top: -3px">4</p>
                    </v-col>
                    <v-col
                      @click="onChangeMultipleAmt('5')"
                      cols="1"
                      style="
                        border: 1px solid #000;
                        height: 40px;
                        background-color: #fcdc00;
                        text-align: center;
                      "
                      v-if="buyType == '5'"
                    >
                      <p style="margin-top: -3px">5</p>
                    </v-col>
                    <v-col
                      @click="onChangeMultipleAmt('5')"
                      cols="1"
                      style="border: 1px solid #000; height: 40px"
                      v-else
                    >
                      <p style="margin-top: -3px">5</p>
                    </v-col>
                    <v-col
                      @click="onChangeMultipleAmt('6')"
                      cols="1"
                      style="
                        border: 1px solid #000;
                        height: 40px;
                        background-color: #fcdc00;
                        text-align: center;
                      "
                      v-if="buyType == '6'"
                    >
                      <p style="margin-top: -3px">6</p>
                    </v-col>
                    <v-col
                      @click="onChangeMultipleAmt('6')"
                      cols="1"
                      style="border: 1px solid #000; height: 40px"
                      v-else
                    >
                      <p style="margin-top: -3px">6</p>
                    </v-col>
                    <v-col
                      style="
                        border: 1px solid #000;
                        height: 40px;
                        border: 1px solid #000;
                        height: 40px;
                        background-color: #0093dd;
                        color: white;
                      "
                      cols="2"
                    >
                      <p style="margin-top: -3px">ตัว</p>
                    </v-col>
                  </v-row>
                </v-col>
              <v-col cols="12" md="4" sm="12">
                 <v-row justify="center" align="center">
                        <v-col
                          cols="2"
                          style="padding-left: 10px; padding-right: 10px"
                        >
                          <v-text-field
                            outlined
                            dense
                            ref="num6"
                            v-model="num6"
                            v-mask="'#'"
                            :readonly="isDisable6"
                            :placeholder="isDisable6 ? 'X' : ''"
                            @click="warningAlert(isDisable6)"
                            style="text-align-last: center"
                          >
                            <v-slot></v-slot>
                          </v-text-field>
                        </v-col>
                        <v-col
                          cols="2"
                          style="padding-left: 10px; padding-right: 10px"
                        >
                          <v-text-field
                            outlined
                            dense
                            ref="num5"
                            v-model="num5"
                            v-mask="'#'"
                            :readonly="isDisable5"
                            :placeholder="isDisable5 ? 'X' : ''"
                            @click="warningAlert(isDisable5)"
                            style="text-align-last: center"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          cols="2"
                          style="padding-left: 10px; padding-right: 10px"
                        >
                          <v-text-field
                            outlined
                            dense
                            ref="num4"
                            v-model="num4"
                            v-mask="'#'"
                            :placeholder="isDisable4 ? 'X' : ''"
                            :readonly="isDisable4"
                            @click="warningAlert(isDisable4)"
                            style="text-align-last: center"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          cols="2"
                          style="padding-left: 10px; padding-right: 10px"
                        >
                          <v-text-field
                            outlined
                            dense
                            ref="num3"
                            v-model="num3"
                            v-mask="'#'"
                            :readonly="isDisable3"
                            :placeholder="isDisable3 ? 'X' : ''"
                            @click="warningAlert(isDisable3)"
                            style="text-align-last: center"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          cols="2"
                          style="padding-left: 10px; padding-right: 10px"
                        >
                          <v-text-field
                            outlined
                            dense
                            ref="num2"
                            v-model="num2"
                            v-mask="'#'"
                            :readonly="isDisable2"
                            :placeholder="isDisable2 ? 'X' : ''"
                            @click="warningAlert(isDisable2)"
                            style="text-align-last: center"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col
                          cols="2"
                          style="padding-left: 10px; padding-right: 10px"
                        >
                          <v-text-field
                            outlined
                            dense
                            ref="num1"
                            v-model="num1"
                            v-mask="'#'"
                            :readonly="isDisable1"
                            :placeholder="isDisable1 ? 'X' : ''"
                            @click="warningAlert(isDisable1)"
                            style="text-align-last: center"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
              </v-col>
              <v-col cols="12">
                <v-row
                  class="text-center"
                  align="center"
                  justify="center"
                  no-gutters
                >
                  <v-col cols="12" md="4" sm="4" xs="4">
                    <p style="margin-top: 0px">เลือกราคาชุดนี้</p>
                  </v-col>
                  <v-col cols="12" md="6" sm="6" xs="6">
                    <v-select
                      v-model="selectedPrice"
                      :items="itemsPriceList"
                      item-text="name"
                      item-value="value"
                      placeholder="กรุณาเลือกราคา"
                      outlined
                      dense
                      hide-details
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="mb-4">
                <v-btn
                  @click="onClickAdd()"
                  large
                  color="primary"
                  style="margin-top: -10px"
                  >เพิ่มสลากชุดนี้</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" style="background: #fcdc00">
          <v-col cols="12">
            <h3>เลขสลากที่เลือกเพิ่ม :</h3>
          </v-col>
          <v-col cols="12" v-if="slectedSet.length == 0">
            <h3 style="color: #f44336">ยังไม่ได้เลือก</h3>
          </v-col>
          <v-row
            style="padding: 5px"
            class="text-center"
            v-for="(i, index) in slectedSet"
            :key="index"
            no-gutters
            dense
          >
            <v-col cols="3" style="margin-left: -10px">
              <!-- {{ aToz[index] }} -->
              <span style="font-size: 0.9rem">แถวที่ {{ index + 1 }}</span>
            </v-col>
            <!-- <v-col cols="1">
              <v-icon small @click="randomRow(index)">mdi-autorenew</v-icon>
            </v-col> -->
            <v-col cols="4">
              <span
                v-for="(j, indexj) in i.set"
                :key="indexj"
                style="font-size: 0.9rem"
              >
                {{ j }}
              </span>
            </v-col>
            <v-col cols="4">
              <!-- {{ aToz[index] }} -->
              <span style="font-size: 0.9rem"
                >ราคา {{ i.price * 100 }} กีบ <br />
                ({{ i.price }} บาท)</span
              >
            </v-col>
            <v-col cols="1" style="margin-left: 10px">
              <v-icon small @click="deleteRow(index)">mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12">
          <p style="font-size: 20px">
            <b>ช่องทางการชำระเงิน:</b>
            {{
              tranxDetail.paymentType == "PAYSOLUTION"
                ? "PAYSOLUTION"
                : "โอนเงิน"
            }}
          </p>
        </v-col>
        <v-col
          cols="12"
          md="12"
          v-if="tranxDetail.paymentType == 'PAYSOLUTION'"
        >
          <p style="font-size: 20px">
            <b>รหัสชำระเงิน:</b> {{ tranxDetail.paysolutionId }}
          </p>
        </v-col>
        <v-col cols="12" md="12">
          <p style="font-size: 20px">
            <b>จำนวนเงินทั้งหมด:</b> {{ tranxDetail.totalPrice }} บาท
          </p>
        </v-col>
        <v-col cols="12" md="3" sm="3" class="pt-5"
          ><span><b>โอนคืนลูกค้า</b></span>
        </v-col>
        <v-col cols="12" md="7" sm="9">
          <v-text-field
            placeholder="เงินที่ต้องโอนคืนลูกค้า"
            v-model="tranxDetail.refundPrice"
            dense
            solo
          >
          </v-text-field>
        </v-col>
        <!-- <v-col cols="6" md="6">
          <span style="font-size: 20px">
            <b>จองคำสั่งซื้อนี้: </b>
          </span>
          <span style="font-size: 20px">
            <v-checkbox v-model="reserve">
          </v-checkbox>
          </span>
        </v-col> -->
        <v-col cols="12">
          <v-btn color="green" dark @click="sendSuccessSMS(user.telNumber)"
            >ส่ง SMS ให้ผู้สั่งซื้อ</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-btn
            color="green"
            dark
            @click="sendSuccessSMS(tranxDetail.receiveTel)"
            >ส่ง SMS ให้ผู้อื่น</v-btn
          >
        </v-col>
      </v-row>

      <br />
      <br />
      <v-row>
        <v-btn @click="cancel()">กลับ</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="submit()" color="primary">บันทึก</v-btn>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import moment from "moment";
import { Decode } from "@/services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      // isDisable1: true,
      // isDisable2: true,
      // isDisable3: true,
      // isDisable4: true,
      // isDisable5: true,
      // isDisable6: true,
      selectedPrice: 10,
      itemsPriceList: [
        {
          name: "1000 กีบ (10 บาท)",
          value: 10,
        },
        {
          name: "2000 กีบ (20 บาท)",
          value: 20,
        },
        // {
        //   name: "3000 กีบ (30 บาท)",
        //   value: 30,
        // },
      ],
      num1: "",
      num2: "",
      num3: "",
      num4: "",
      num5: "",
      num6: "",
      buyType: "6",
      isDisable1: true,
      isDisable2: true,
      isDisable3: true,
      isDisable4: true,
      isDisable5: true,
      isDisable6: true,
      loading: false,
      reserve: false,
      search: "",
      remark: "",
      count: 1,
      id: "",
      adminName: "",

      status: "",
      itemsStatus: [
        { text: "มีปัญหา", value: "มีปัญหา" },
        { text: "ยกเลิก", value: "ยกเลิก" },
        // { text: "อนุมัติ/ยืนยันสลิปถูกต้อง", value: "สั่งซื้อสำเร็จ" },
        { text: "รอการชำระเงิน", value: "รอการชำระเงิน" },
        { text: "ชำระเงินสำเร็จ", value: "ชำระเงินสำเร็จ" },
        { text: "สั่งซื้อสำเร็จ(อัพโหลดตั๋วแล้ว)", value: "สั่งซื้อสำเร็จ" },
      ],
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      deliveryAddress: "",
      deliveryStatus: "",
      itemsDeliveryStatus: [
        { text: "รอการจัดส่ง", value: "WAITING" },
        { text: "จัดส่งแล้ว", value: "SENT" },
      ],
      isDelivery: false,
      lotteryDate: "",
      lotteryTransaction: [],
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      pic2: "",
      picture_2: "",
      showImage2: "",
      imageName2: "",
      slipImgUrl: "",
      status: "",
      totalPrice: "",
      user: [],
      // orderProducts: [],
      headers: [
        {
          text: "แถวที่",
          value: "count",
          align: "center",
        },
        {
          text: "เลขที่ซื้อ",
          value: "numberData",
          align: "center",
          width: "500px",
        },
        {
          text: "ราคาที่ซื้อ",
          value: "price",
          align: "center",
          width: "200px",
        },
        {
          text: "ยกเลิก",
          value: "cancel",
          align: "center",
          width: "70px",
        },
      ],
      // createdAt: "2021-04-24T18:16:52.000Z"
      // id: 139
      // lotteryDate: "2020-08-16"
      // lotteryNo: 309072
      // owner: "admin"
      // price: 80
      // set: 23
      // stock: 0
      // time: 34
      // updatedAt: "2021-06-29T03:25:01.000Z"
      // urlImage: "https://readylotserv
      // lottery: (...),
      tranxDetail: [],
      powerBallStatusData: null,
      trasactionId: "",
      showUserName: "",
      keytab: "",
      slectedSet: []
    };
  },
  watch: {
    num6(val) {
      if (val) {
        this.$refs.num5.focus();
      }
    },
    num5(val) {
      if (val) {
        this.$refs.num4.focus();
      } else {
        this.$refs.num6.focus();
      }
    },
    num4(val) {
      if (val) {
        this.$refs.num3.focus();
      } else {
        this.$refs.num5.focus();
      }
    },
    num3(val) {
      if (val) {
        this.$refs.num2.focus();
      } else {
        this.$refs.num4.focus();
      }
    },
    num2(val) {
      if (val) {
        this.$refs.num1.focus();
      } else {
        this.$refs.num3.focus();
      }
    },
    num1(val) {
      if (!val) {
        this.$refs.num2.focus();
      }
    },
  },
  created() {
    this.onChangeMultipleAmt();
    this.keytab = this.$route.query.tab;
    var orderdata = JSON.parse(
      Decode.decode(localStorage.getItem("orderdata"))
    );
    console.log("orderdata", orderdata);
    this.id = orderdata.id;
    var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
    console.log("userdata", user);
    this.adminName = user.user.firstName + " " + user.user.lastName;

    // this.orderNo = orderdata.orderNo;
    // this.orderType = orderdata.orderType;
    // this.orderdate = new Date(orderdata.orderdate).toISOString().slice(0, 10);
    // this.totalPrice = orderdata.totalPrice;
    // this.paymentType = orderdata.paymentType;
    // this.status = orderdata.status;
    // this.fullname = orderdata.user.firstname + " " + orderdata.user.lastname;
    // this.tel = orderdata.tel;
    // this.address = orderdata.address;
    this.getOneOrder();
  },
  methods: {
    warningAlert(val) {
      if (val) {
        console.log("clickkkk");
        this.$swal.fire({
          icon: "error",
          text: "กรุณากรอกตัวเลขเฉพาะช่องที่ไม่มีเครื่องหมาย X",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    },
    onChangeMultipleAmt(amt) {
      console.log(amt);
      this.num1 = "";
      this.num2 = "";
      this.num3 = "";
      this.num4 = "";
      this.num5 = "";
      this.num6 = "";

      this.buyType = amt;
      // this.buyType = "6";

      if (this.buyType == "2") {
        this.isDisable1 = false;
        this.isDisable2 = false;
        this.isDisable3 = true;
        this.isDisable4 = true;
        this.isDisable5 = true;
        this.isDisable6 = true;
      } else if (this.buyType == "3") {
        this.isDisable1 = false;
        this.isDisable2 = false;
        this.isDisable3 = false;
        this.isDisable4 = true;
        this.isDisable5 = true;
        this.isDisable6 = true;
      } else if (this.buyType == "4") {
        this.isDisable1 = false;
        this.isDisable2 = false;
        this.isDisable3 = false;
        this.isDisable4 = false;
        this.isDisable5 = true;
        this.isDisable6 = true;
      } else if (this.buyType == "5") {
        this.isDisable1 = false;
        this.isDisable2 = false;
        this.isDisable3 = false;
        this.isDisable4 = false;
        this.isDisable5 = false;
        this.isDisable6 = true;
      } else if (this.buyType == "6") {
        this.isDisable1 = false;
        this.isDisable2 = false;
        this.isDisable3 = false;
        this.isDisable4 = false;
        this.isDisable5 = false;
        this.isDisable6 = false;
      }
    },
    async deleteRow(index) {
      this.slectedSet.splice(index, 1);
      // this.totalPrice = this.slectedSet.length * 150;
      this.tranxDetail.totalPrice = 0;
      for (let index = 0; index < this.slectedSet.length; index++) {
        const element = this.slectedSet[index];
        this.tranxDetail.totalPrice = this.tranxDetail.totalPrice + element.price;
      }
      for (let index = 0; index < this.tranxDetail.numberData.length; index++) {
        const element = this.tranxDetail.numberData[index];
        this.tranxDetail.totalPrice = this.tranxDetail.totalPrice + element.price;
      }
    },
    onClickAdd() {
      console.log(this.slectedSet);

      var that = this;

      // var findType = this.slectedSet.filter(function (el) {
      //   return el.buyType == that.buyType;
      // });

      // console.log(findType);

      // if (this.buyType == "6" && findType.length > 0) {
      //   this.$swal.fire({
      //     icon: "error",
      //     text: "ไม่สามารถเลือกเลขซ้ำกับแถวเดิมได้",
      //     showConfirmButton: true,
      //     // timer: 1500,
      //   });

      //   return;
      // }

      if (this.buyType == "2") {
        if (this.num1 !== "" && this.num2 !== "") {
          this.slectedSet.push({
            set: [this.num2, this.num1],
            price: this.selectedPrice,
            isCancel: false,
            buyType: this.buyType,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: "กรุณาระบุตัวเลขให้ครบทุกช่อง",
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      } else if (this.buyType == "3") {
        if (this.num1 !== "" && this.num2 !== "" && this.num3 !== "") {
          this.slectedSet.push({
            set: [this.num3, this.num2, this.num1],
            price: this.selectedPrice,
            isCancel: false,
            buyType: this.buyType,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: "กรุณาระบุตัวเลขให้ครบทุกช่อง",
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      } else if (this.buyType == "4") {
        if (
          this.num1 !== "" &&
          this.num2 !== "" &&
          this.num3 !== "" &&
          this.num4 !== ""
        ) {
          this.slectedSet.push({
            set: [this.num4, this.num3, this.num2, this.num1],
            price: this.selectedPrice,
            isCancel: false,
            buyType: this.buyType,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: "กรุณาระบุตัวเลขให้ครบทุกช่อง",
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      } else if (this.buyType == "5") {
        if (
          this.num1 !== "" &&
          this.num2 !== "" &&
          this.num3 !== "" &&
          this.num4 !== "" &&
          this.num5 !== ""
        ) {
          this.slectedSet.push({
            set: [this.num5, this.num4, this.num3, this.num2, this.num1],
            price: this.selectedPrice,
            isCancel: false,
            buyType: this.buyType,
          });
        } else {
          this.$swal.fire({
            icon: "error",
            text: "กรุณาระบุตัวเลขให้ครบทุกช่อง",
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      } else if (this.buyType == "6") {
        if (
          this.num1 !== "" &&
          this.num2 !== "" &&
          this.num3 !== "" &&
          this.num4 !== "" &&
          this.num5 !== "" &&
          this.num6 !== ""
        ) {
          this.slectedSet.push({
            set: [
              this.num6,
              this.num5,
              this.num4,
              this.num3,
              this.num2,
              this.num1,
            ],
            price: this.selectedPrice,
            isCancel: false,
            buyType: this.buyType,
          });

          // this.totalPrice = this.slectedSet.length * 150;
        } else {
          this.$swal.fire({
            icon: "error",
            text: "กรุณาระบุตัวเลขให้ครบทุกช่อง",
            showConfirmButton: true,
            // timer: 1500,
          });
        }
      }

      this.num1 = "";
      this.num2 = "";
      this.num3 = "";
      this.num4 = "";
      this.num5 = "";
      this.num6 = "";

      this.tranxDetail.totalPrice = 0;
      for (let index = 0; index < this.slectedSet.length; index++) {
        const element = this.slectedSet[index];
        this.tranxDetail.totalPrice = this.tranxDetail.totalPrice + element.price;
      }
      for (let index = 0; index < this.tranxDetail.numberData.length; index++) {
        const element = this.tranxDetail.numberData[index];
        this.tranxDetail.totalPrice = this.tranxDetail.totalPrice + element.price;
      }
    },
    async sendSuccessSMS(tel) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/sendSuccessSMSLao/${tel}/${this.tranxDetail.transactionNo}`
        // 'http://localhost:8081/categories/'
      );
      this.$swal.fire({
        icon: "success",
        text: `ส่งข้อความสำเร็จ`,
        showConfirmButton: false,
        timer: 1500,
      });
    },
    async getPowerballStatus() {
      // console.log("user", user.token);

      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/transaction/getPowerballData/` + this.id,
        {}
      );
      console.log("response powerball", response.data.data);
      this.powerBallStatusData = response.data.data;
    },
    newWindow(val) {
      window.open(val);
    },
    convertDate(val) {
      return moment(val).format("MM/DD/YYYY HH:mm");
    },
    async getOneOrder() {
      var user = JSON.parse(Decode.decode(localStorage.getItem("DDLotAdmin")));
      console.log(user);
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/getLaoTrx/` + this.id,
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("getOneOrder", response.data);
      this.tranxDetail = response.data.data;
      this.tranxDetail.numberData = JSON.parse(this.tranxDetail.selectedNumber);
      const orderdata = response.data.data;
      // this.id = orderdata.id;
      this.deliveryStatus = orderdata.deliveryStatus;
      this.lotteryTransaction = orderdata.lotteryTransaction;
      this.showImage2 = orderdata.slipImgUrl;
      this.showImage = orderdata.scanedTicket;
      this.status = orderdata.status;
      this.totalPrice = orderdata.totalPrice;
      this.user = orderdata.user;
      this.isDelivery = orderdata.isDelivery;
      this.remark = orderdata.remark;
      if (this.isDelivery) {
        this.deliveryAddress = orderdata.deliveryAddress;
      }
      this.showUserName = orderdata.adminName;
      // this.getPowerballStatus();
    },
    cancel() {
      this.$router.push({
        path: "/ManageLaoOrder",
        query: {
          tab: this.keytab,
        },
      });
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    showPicture2(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic2 = reader.result;
          this.showImage2 = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
    // onPickFile() {
    //   document.getElementById("file_input").click();
    // },
    async submit() {
      console.log(this.tranxDetail.numberData);
      for (let index = 0; index < this.slectedSet.length; index++) {
        const element = this.slectedSet[index];
        this.tranxDetail.numberData.push(element)
      }
      if (this.$refs.createForm.validate(true)) {
        this.loading = true;
        const data = {
          status: this.status,
          adminName: this.adminName,
          remark: this.remark,
          selectedNumber: JSON.stringify(this.tranxDetail.numberData),
          refundPrice: this.tranxDetail.refundPrice,
          totalPrice: this.tranxDetail.totalPrice
        };
        if (this.pic2 !== "") {
          data.slipImg = this.pic2;
        } else {
          data.slipImgUrl = this.showImage2;
        }
        if (this.pic1 !== "") {
          data.scanedTicket = this.pic1;
        } else {
          data.scanedTicketUrl = this.showImage;
        }

        console.log("datasubmit", data);

        var user = JSON.parse(
          Decode.decode(localStorage.getItem("DDLotAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/transaction/updateLao/` + this.id,
          data,
          auth
        );
        console.log("update status", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `อัพเดตข้อมูลสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push({
            path: "/ManageLaoOrder",
            query: {
              tab: this.keytab,
            },
          });
          this.loading = false;
        } else {
          this.$swal.fire({
            icon: "error",
            text: `อัพเดตข้อมูลไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    RemoveImage(index, item) {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      // this.product_image.splice(index, 1);
      this.pic1 = [];
      this.picture_1 = "";
      this.slipImg = "";
      this.showImage = "";
      this.imageName1 = "";
    },
  },
};
</script>